import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Carousel, { Dots } from '@brainhubeu/react-carousel'

import { companiesPageSelector } from '@data'
import { getPrismicText } from '@utils'

import {
  NavBar,
  Hero,
  Perks,
  Works,
  Satisfaction,
  Brands,
  Data,
  BodyCard,
  Ready,
  Footer,
  SEO,
} from '@components'

import '@brainhubeu/react-carousel/lib/style.css'
import '@styles/companies.scss'

const Companies = (props) => {
  const { data: prismic = {} } = { ...props }
  const data = companiesPageSelector(prismic)
  
  const { perks = {}, perksSectionItems = [], bodyCard = {}, bodyCardSectionItems = [], carouselTestimonialItems = [] } = { ...data }
  const { title: perksSectionTitle = '' } = {  ...perks }
  const { title: bodyCardSectionTitle = '', description: bodyCardSectionParagraph } = {  ...bodyCard }

  const [value, setValue] = useState(0)
  const onchange = (value) => setValue(value)

  const imagesThumb = carouselTestimonialItems.map(
    (carouselItem) => {
      const {
        companies_carousel_image,
        companies_carousel_name,
      } = { ...carouselItem }
      return (
        <div className={'img-thumb'}>
          <img
            src={companies_carousel_image.url}
            alt={getPrismicText(companies_carousel_name)}
          />
        </div>
      )
    }
  )

  return (
    <section>
      <SEO title={'Companies'} />
      <NavBar data={data} />
      <Hero section={'companies'} data={data} />
      <section className={'big-blue-companies'}>
        <section className={'companies'}>
          <div className={'companies__container'}>
            <h2
              className={'companies__container-title'}
              data-sal={'fade'}
              data-sal-delay={'50'}
              data-sal-easing={'ease'}
              data-sal-duration={'800'}
            >
              {perksSectionTitle}
            </h2>
            <section className={'perks-companies'}>
              {(perksSectionItems || []).map((perksSectionItem, index) => {
                return (
                  <Perks
                    key={index}
                    perksSectionItem={perksSectionItem}
                    index={index}
                  />
                )
              })}
            </section>
          </div>
        </section>
        <section className={'how-it-works-companies'}>
          <Works data={data} />
        </section>
        <Satisfaction data={data} />
        <section className={'look-mobile-companies'}>
          <Data
            data={data}
            section={'companies'}
            showButton
          />
        </section>
        <section className={'body-card'}>
          <div className={'body-card__head'}>
            <h2
              data-sal={'fade'}
              data-sal-delay={'50'}
              data-sal-easing={'ease'}
              data-sal-duration={'800'}
            >
              {bodyCardSectionTitle}
            </h2>
            <p
              data-sal={'fade'}
              data-sal-delay={'100'}
              data-sal-easing={'ease'}
              data-sal-duration={'800'}
            >
              {bodyCardSectionParagraph}
            </p>
          </div>
          <div className={'body-card__content'}>
            {(bodyCardSectionItems || []).map((bodyCardSectionItem, index) => {
              return (
                <BodyCard
                  key={index}
                  index={index}
                  bodyCardSectionItem={bodyCardSectionItem}
                />
              )
            })}
          </div>
        </section>
        <Brands data={data} />
        <section className={'testimonials-carousel-bg'}>
          <div className={'testimonials'}>
            <Carousel
              autoPlay={10000}
              infinite
              onChange={onchange}
              value={value}
            >
              {(carouselTestimonialItems || []).map(
                (carouselItem) => {
                  const {
                    companies_carousel_image,
                    companies_carousel_name,
                    companies_carousel_diagnosis,
                    companies_carousel_testimonial,
                    companies_carousel_description,
                  } = { ...carouselItem }
                  return (
                    <div className={'testimonials-container'} key={getPrismicText(companies_carousel_name)}>
                      <div className={'testimonials-container__left'}>
                        <div className={'testimonials-container__left-image'}>
                          <img
                            src={companies_carousel_image.url}
                            alt={getPrismicText(companies_carousel_name)}
                          />
                        </div>
                        <p className={'testimonials-container__left-name'}>
                          {getPrismicText(companies_carousel_name)}
                        </p>
                        <p className={'testimonials-container__left-job'}>
                          {getPrismicText(companies_carousel_diagnosis)}
                        </p>
                      </div>
                      <div className={'testimonials-container__right'}>
                        <h2>
                          {getPrismicText(companies_carousel_testimonial)}
                        </h2>
                        <p>
                          {getPrismicText(companies_carousel_description)}
                        </p>
                      </div>
                    </div>
                  )
                }
              )}
            </Carousel>
            <Dots thumbnails={imagesThumb} onChange={onchange} value={value} />
          </div>
        </section>
        <section className={'ready'}>
          <Ready data={data} />
        </section>
      </section>
      <Footer data={data} />
    </section>
  )
}

export const query = graphql`
  query CompaniesPageQuery($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    prismicCompaniesPage(lang: { eq: $lang }) {
      data {
        body {
          primary {
            navbar_section_logo {
              url
            }
            navbar_section_logo_mobile {
              url
            }
            navbar_section_cta_label_home {
              text
            }
            navbar_section_cta_link_home {
              text
            }
            navbar_section_cta_label_providers {
              text
            }
            navbar_section_cta_link_providers {
              text
            }
            navbar_section_cta_label_companies {
              text
            }
            navbar_section_cta_link_companies {
              text
            }
            navbar_section_cta_label_demo {
              text
            }
            navbar_section_cta_link_demo {
              url
            }
            navbar_section_cta_label_login {
              text
            }
            navbar_section_cta_link_login {
              url
            }
            navbar_section_cta_label_switcher {
              text
            }
            navbar_section_cta_link_switcher {
              text
            }
            hero_cta_label {
              text
            }
            hero_cta_link {
              url
            }
            hero_image {
              url
            }
            hero_paragraph {
              text
            }
            hero_title {
              text
            }
            perks_section_title {
              text
            }
            works_section_title {
              text
            }
            works_section_paragraph {
              text
            }
            satisfaction_section_title {
              text
            }
            brands_section_title {
              text
            }
            data_section_title {
              text
            }
            data_section_paragraph {
              text
            }
            data_section_cta_label {
              text
            }
            data_section_cta_link {
              url
            }
            data_section_image {
              url
            }
            body_card_section_title {
              text
            }
            body_card_section_paragraph {
              text
            }
            ready_section_title {
              text
            }
            ready_section_cta_label {
              text
            }
            ready_section_paragraph {
              text
            }
            ready_section_cta_link {
              url
            }
            footer_section_logo {
              url
            }
            footer_section_cta_label_about {
              text
            }
            footer_section_cta_label_with_us {
              text
            }
            footer_section_cta_label_press {
              text
            }
            footer_section_cta_link_press {
              text
            }
            footer_section_cta_label_contact {
              text
            }
            footer_section_logo_facebook {
              url
            }
            footer_section_cta_link_facebook {
              url
            }
            footer_section_logo_instagram {
              url
            }
            footer_section_cta_link_instagram {
              url
            }
            footer_section_logo_linkedin {
              url
            }
            footer_section_cta_link_linkedin {
              url
            }
            footer_section_copyright {
              text
            }
            footer_section_cta_label_privacy {
              text
            }
            footer_section_cta_label_terms {
              text
            }
          }
          items {
            perk_image {
              url
            }
            perk_title {
              text
            }
            perk_paragraph {
              text
            }
            works_image {
              url
            }
            works_title {
              text
            }
            works_paragraph {
              text
            }
            satisfaction_image {
              url
            }
            satisfaction_subtitle {
              text
            }
            satisfaction_description {
              text
            }
            brand_image {
              url
            }
            companies_carousel_image {
              url
            }
            companies_carousel_name {
              text
            }
            companies_carousel_diagnosis {
              text
            }
            companies_carousel_testimonial {
              text
            }
            companies_carousel_description {
              text
            }
            card_image {
              url
            }
            card_title {
              text
            }
            card_paragraph {
              text
            }
          }
        }
      }
    }
  }
`

export default Companies
